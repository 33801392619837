export default {
  title: 'Welcome',
  subtitle: 'check out my portfolio!',
  anchors: [
    {
      name: '3D Graphics',
      anchor: '#section-graphics'
    },
    {
      name: 'Visualizations',
      anchor: '#section-unity'
    },
    {
      name: 'Animations',
      anchor: '#section-unreal'
    },
    {
      name: 'Game Assets',
      anchor: '#section-assets'
    }
  ],
  icons: [
    {
      icon: 'facebook',
      href: 'https://www.facebook.com/maciej.suszko'
    },
    {
      icon: 'deviantart',
      href: 'http://nemusnem.deviantart.com'
    },
    {
      icon: 'youtube',
      href: 'https://www.youtube.com/channel/UC4rFpcybjB7hknrPKB6Te1Q'
    },
    {
      icon: 'linkedin',
      href: 'https://linkedin.com/in/maciej-suszko-b55340134'
    },
    {
      icon: 'bitbucket',
      href: 'https://bitbucket.org/N3mus/portfolio/src/master/'
    },
  ]
}

