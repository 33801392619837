import Vue from 'vue/dist/vue.js'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { router, VueRouter } from './routes'
import '../css/variables/queries.css'
import '../components/gallery/main.css'
import '../components/skill/main.css'
import '../css/main.css'
import '../css/components/menu.css'
import '../css/sections/home.css'
import '../css/sections/artist.css'
import '../css/sections/webdev.css'
import '../css/sections/footer.css'
import '../css/sections/artist-gallery.css'

Vue.use(VueRouter)

library.add(faBars, faWindowClose, faTimes)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.prototype.$http = Axios;
Vue.config.productionTip = false

new Vue({
  router,
  data() {
    return {
      prevHeight: 0,
      transitionName: 'fade',
      };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;
      if (transitionName === 'slide') {
        const toDepth = to.path.split('/');
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth[toDepth.length - 1] === '' || toDepth.length < fromDepth  ? 'slide-right' : 'slide-left';
      }

        this.transitionName = transitionName || 'fade';

        next();
    });
  },
}).$mount('#body');
