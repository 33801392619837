<template>
  <div class="hello">
    <h1>Welcome to administrator page</h1>
    <form ref='uploadForm'
      id='uploadForm'
      action='http://msuszko.pl:3008/upload'
      method='post'
      encType="multipart/form-data"
      @submit.prevent="onSubmit"
      >
        <input type="file" ref="file" @change="onSelect" name="sampleFile" />
        <input type='submit' value='Upload!' />
    </form>
    <p>original file size: {{size}}</p>
    <p>minified file size: {{fileSize}}</p>
    <p>thumbnail minified file size: {{thumbnailSize}}</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      file: '',
      size: '',
      fileSize: '',
      thumbnailSize: ''
    }
  },
  methods: {
      onSelect(){
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        const file = this.$refs.file.files[0]
        this.file = file

        if(!allowedTypes.includes(file.type)) {
          this.message = "Only images are required!"
        }

        if (file.size > 5000000) {
          this.message = "Too large, max size allowed is 5MB"
        }
      },
      onSubmit(){
        let formData = new FormData();
        formData.append('sampleFile', this.file);
        try {
          this.$http.post('http://msuszko.pl:3008/upload', formData)
            .then(res => {
              this.size = res.data.file.size
              this.fileSize = res.data.fileSize
              this.thumbnailSize = res.data.thumbnailSize
            })
        } catch (err) {
          console.log(err)
        }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>