<template>
<div class="art-gallery">
    <p class="art-gallery_rotate-title">using</p>
    <h1 class="art-gallery_title">{{section.title}}</h1>
    <img class="art-gallery_img" :src="section.background" alt="">
    <div class="art-gallery_desc">
        <p v-for="desc in section.descriptions" :key="desc.id" class="art-gallery_text" v-html="desc">{{desc}}</p>
    </div>
    <div v-if="section.movies" class="art-gallery_video">
        <iframe :key="movie.id" v-for="movie in section.movies" class="videoFrame" :src="movie" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </div>
    <gallery :thumbnails="section.gallery.thumbnails" :images="section.gallery.images" :anchor="section.anchor"></gallery>
</div>
</template>

<script>
import Gallery from '../components/gallery/Gallery'
import { graphics, visualizations, animations } from '../js/artistGallery'

const galleries = {
    graphics,
    visualizations,
    animations
}

export default {
    props: ['type'],
    data() {
        return {
            section: galleries[this.$options.propsData.type]
        }
    },
    components: {
        Gallery
    },
    beforeRouteUpdate(to, from, next) {
        this.section = galleries[to.params.type]
        next()
    }
}
</script>
