<template>
<div class="webdev">
    <div class="webdev_banner">
        <video class="webdev_video" src="../images/webdev.mp4" autoplay loop></video>
    </div>
    <div class="webdev_strip">
        <h1 class="webdev_title">{{webdev.title}}</h1>
    </div>
    <div class="webdev_skills">
        <skill-list v-for="skills in webdev.skills" :key="skills.id" :skills="skills"></skill-list>
    </div>
</div>
</template>

<script>
import SkillList from '../components/skill/SkillList'
import { default as webdev } from '../js/webDev'

export default {
    data: () => ({webdev}),
    components: {
        SkillList
    }
}
</script>
