<template>
    <nav class="sidebar" v-bind:class="{'sidebar-open': isOpen}">
        <button class="sidebar_btn" v-bind:class="{'sidebar_btn-open': isOpen}" @click="toggleMenu">
            <font-awesome-icon class="sidebar_icon sidebar_icon-bars" v-bind:class="{'sidebar_icon-open': isOpen}" icon="bars" size="3x"></font-awesome-icon>
            <font-awesome-icon class="sidebar_icon sidebar_icon-close" v-bind:class="{'sidebar_icon-open': !isOpen}" icon="times" size="3x"></font-awesome-icon>
        </button>
        <ul class="sidebar_nav">
            <li class="sidebar_item"><router-link class="sidebar_link" :to="{name: 'home'}">Home</router-link></li>
            <li class="sidebar_item"><router-link class="sidebar_link" :to="{name: 'webdev'}">Web Dev</router-link></li>
            <li class="sidebar_item"><router-link class="sidebar_link" :to="{path: '/3dartist/graphics'}">3D Artist</router-link></li>
        </ul>
    </nav>
</template>

<script>

export default {
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        toggleMenu() {
            this.isOpen = !this.isOpen
        }
    }
}
</script>
