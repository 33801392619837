import sectionBlenderImage from '../images/blender.png'
import sectionUnityImage from '../images/unity.png'
import sectionUnrealImage from '../images/unreal.png'
import sectionAssetsImage from '../images/game-assets.png'
import {gameAssets, blender, unity, unreal} from './galleriesDatabase'

export const assets = {
    galleries: gameAssets,
    background: sectionAssetsImage,
    title: 'Game Assets',
    descriptions: [
        'I can model and texture <span class="art-gallery_text-highlight">GAME ASSETS</span> in PBR.',
        'I love create procedural models.'
    ]
}

export const graphics = {
    gallery: blender,
    background: sectionBlenderImage,
    title: 'Blender',
    descriptions: [
        'My compositions based on <span class="art-gallery_text-highlight">Cycles Render Engine</span>.',
        'I like to create fantasy scenes, exteriors, landscapes, interiors.'
    ]
}

export const visualizations = {
    gallery: unity,
    background: sectionUnityImage,
    title: 'Unity3D',
    descriptions: [
        `I'm familiar with <span class="art-gallery_text-highlight">Unity3D</span> and <span class="section_text-highlight">Unreal Engine</span>.`,
        `I like to create <span class="art-gallery_text-highlight">PHOTOREALISTIC</span> visualizations with interaction possibility.`,
        `I know Unity environment very well and <span class="art-gallery_text-highlight">PHYSICALLY BASED RENDERING</span> model.`
    ],
    movies: [
        'https://www.youtube.com/embed/bAe3dPmvw90'
    ]
}

export const animations = {
    gallery: unreal,
    background: sectionUnrealImage,
    title: 'Unreal Engine',
    descriptions: [
        `I can create <span class="art-gallery_text-highlight">REALTIME</span> animations based on PBR model.`,
        `Any model needed for animation I can just create using Blender.`
    ],
    movies: [
        'https://www.youtube.com/embed/82hTb1ngHVc',
        'https://www.youtube.com/embed/wDkMqvO09TA'
    ]
}