
import Home from '../views/Home.vue'
import Menu from '../views/Menu.vue'
import WebDev from '../views/WebDev.vue'
import Artist from '../views/Artist.vue'
import ArtistGallery from '../views/ArtistGallery.vue'
import ArtistGameAssets from '../views/ArtistGameAssets.vue'
import VueRouter from 'vue-router'
import Login from '../components/login/Login.vue'
import Admin from '../components/admin/Admin.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/webdev',
        name: 'webdev',
        components: {
            default: WebDev,
            sidebar: Menu
        },
        meta: { transitionName: 'slide' }
    },
    {
        path: '/3dartist',
        components: {
            default: Artist,
            sidebar: Menu
        },
        meta: { transitionName: 'slide' },
        props: true,
        children: [
            {
                path: 'assets',
                name: 'assets',
                component: ArtistGameAssets,
                props: true,
            },
            {
                path: ':type',
                name: 'galleries',
                component: ArtistGallery,
                props: true,
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            guest: true
        }
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        meta: {
            requiresAuth: true,
            is_admin: true
        }
    },
]

export const router = new VueRouter({ routes })

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('jwt') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            let user = JSON.parse(localStorage.getItem('user'))
            if (to.matched.some(record => record.meta.is_admin)) {
                if (user.is_admin == 1) {
                    next()
                }
            }
            else {
                next()
            }
        }
    } else {
        next()
    }
})

export {VueRouter}
