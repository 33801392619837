<template>
<div class="skill_item" v-bind:class="isSubitem">
    <p class="skill_name">{{skill.name}}</p>
    <div class="skill_grade">
        <font-awesome-icon v-for="n in (skill.grade)" :key="n.id" prefix="far" icon="star" class="skill_icon"> </font-awesome-icon><font-awesome-icon v-for="n in (5 - skill.grade)" :key="n.id" :icon="['far', 'star']" class="skill_icon"></font-awesome-icon>
    </div>
</div>
</template>

<script>
import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faStar as solidStar
} from '@fortawesome/free-solid-svg-icons'
import {
    faStar as regularStar
} from '@fortawesome/free-regular-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

library.add(solidStar, regularStar)

export default {
    props: ['skill', 'sub'],
    components: {
        FontAwesomeIcon
    },
    computed: {
        isSubitem: function () {
            return {
                'skill_item-sub': this.sub
            }
        }
    }
}
</script>
