<template>
<div class="home" id="section-home">
    <h1 class="home_title">{{home.title}}</h1>
    <div class="home_links">
        <div class="home_strip">
            <router-link class="home_link" :to="{name: 'webdev'}">Web Dev</router-link>
        </div>
        <div class="home_strip">
            <router-link class="home_link home_link-graphics" :to="{path: '/3dartist/graphics'}">3D Artist</router-link>
        </div>
    </div>
</div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faLinkedin,
    faFacebook,
    faYoutube,
    faDeviantart,
    faBitbucket
} from '@fortawesome/fontawesome-free-brands'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { default as home } from '../js/home'

library.add(faLinkedin, faFacebook, faYoutube, faDeviantart, faBitbucket)

export default {
    data() {
        return {
            home
        }
    },
    components: {
        FontAwesomeIcon
    }
}
</script>
