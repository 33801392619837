<template>
<div class="gallery">
    <div class="gallery_content gallery_content-collapse" v-bind:class="{'gallery_expand': isExpand}">
        <img v-for="(url, name, idx) in thumbnails" :key="idx" :src="url" @click="openLightbox(name)" class="gallery_item" />
        <div v-show="shouldShow()">
            <img :src="images[currentName]" class="gallery_fullscreen" />
            <div class="gallery_lightbox" @click="closeLightbox"></div>
            <button type="button" name="button" class="gallery_close" @click="closeLightbox">
                <font-awesome-icon icon="times" size="2x"></font-awesome-icon>
            </button>
            <div class="gallery_btns">
                <button type="button" name="prev" class="gallery_prev" @click="prevImg(currentName)">
                    <font-awesome-icon icon="chevron-right" flip="horizontal" size="2x"></font-awesome-icon>
                </button>
                <button type="button" name="next" class="gallery_next" @click="nextImg(currentName)">
                    <font-awesome-icon icon="chevron-right" size="2x"></font-awesome-icon>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faChevronRight, faTimes)

export default {
    props: ['thumbnails', 'images', 'anchor'],
    data() {
        return {
            currentName: ''
        }
    },
    components: {
        FontAwesomeIcon
    },
    methods: {
        closeLightbox() {
            window.removeEventListener('keyup', this.handleKeyDown)
            this.currentName = ''
        },
        openLightbox(name) {
            window.addEventListener('keyup', this.handleKeyDown)
            this.currentName = name
        },
        shouldShow() {
            return this.currentName !== '';
        },
        handleKeyDown(event) {
            switch (event.keyCode) {
                case 37:
                    this.prevImg(this.currentName)
                    break;
                case 39:
                    this.nextImg(this.currentName)
                    break;
                case 27:
                    this.closeLightbox()
            }
        },
        nextImg(name) {
            let keys = Object.keys(this.thumbnails)
            let currentIdx = keys.indexOf(name)
            let nextIdx = currentIdx >= keys.length - 1 ? 0 : currentIdx + 1
            this.currentName = keys[nextIdx];
        },
        prevImg(name) {
            let keys = Object.keys(this.thumbnails)
            let currentIdx = keys.indexOf(name)
            let prevIdx = currentIdx <= 0 ? keys.length - 1 : currentIdx - 1
            this.currentName = keys[prevIdx];
        }
    }
}
</script>
