import blenderSmallImages from '../images/blender-small/*.jpg'
import blenderBigImages from '../images/blender/*.jpg'
import unitySmallImages from '../images/unity-small/*.jpg'
import unityBigImages from '../images/unity/*.jpg'
import unrealSmallImages from '../images/unreal-small/*.jpg'
import unrealBigImages from '../images/unreal/*.jpg'
import gameAssetsFerrisWheelSmall from '../images/gameAssets-small/ferrisWheel/*.jpg'
import gameAssetsFerrisWheel from '../images/gameAssets/ferrisWheel/*.jpg'
import gameAssetsModularFactorySmall from '../images/gameAssets-small/modularFactory/*.jpg'
import gameAssetsModularFactory from '../images/gameAssets/modularFactory/*.jpg'
import gameAssetsWoodTowerSmall from '../images/gameAssets-small/woodTower/*.jpg'
import gameAssetsWoodTower from '../images/gameAssets/woodTower/*.jpg'
import gameAssetsWoodBarrelCartSmall from '../images/gameAssets-small/woodBarrelCart/*.jpg'
import gameAssetsWoodBarrelCart from '../images/gameAssets/woodBarrelCart/*.jpg'
import gameAssetsRustyFoldingBridgeSmall from '../images/gameAssets-small/rustyFoldingBridge/*.jpg'
import gameAssetsRustyFoldingBridge from '../images/gameAssets/rustyFoldingBridge/*.jpg'

export const blender = {
    thumbnails: blenderSmallImages,
    images: blenderBigImages,
}

export const unity = {
    thumbnails: unitySmallImages,
    images: unityBigImages,
}

export const unreal = {
    thumbnails: unrealSmallImages,
    images: unrealBigImages,
}

export const gameAssets = [{
    title: 'Ferris Wheel',
    thumbnails: gameAssetsFerrisWheelSmall,
    images: gameAssetsFerrisWheel,
}, {
    title: 'Modular Factory',
    unityLink: 'https://assetstore.unity.com/packages/3d/environments/industrial/modular-factory-building-pbr-136573',
    thumbnails: gameAssetsModularFactorySmall,
    images: gameAssetsModularFactory,
}, {
    title: 'Wood Tower',
    thumbnails: gameAssetsWoodTowerSmall,
    images: gameAssetsWoodTower,
}, {
    title: 'Wood Barrel Cart',
    unityLink: 'https://assetstore.unity.com/packages/3d/vehicles/land/wooden-barrel-cart-pbr-137573',
    thumbnails: gameAssetsWoodBarrelCartSmall,
    images: gameAssetsWoodBarrelCart,
}, {
    title: 'Rusty Folding Bridge',
    unityLink: 'https://assetstore.unity.com/packages/3d/props/rusty-folding-bridge-149581',
    thumbnails: gameAssetsRustyFoldingBridgeSmall,
    images: gameAssetsRustyFoldingBridge,
}]