export const skills = [
  {
    name: 'Core',
    items: [
      {
        name: 'HTML5',
        grade: 5
      }, {
        name: 'CSS3',
        grade: 5
      }, {
        name: 'PostCSS',
        grade: 5,
        sub: true
      }, {
        name: 'Sass',
        grade: 4,
        sub: true
      }, {
        name: 'Less',
        grade: 4,
        sub: true
      }, {
        name: 'JavaScript',
        grade: 3
      }, {
        name: 'ES5',
        grade: 3,
        sub: true
      }, {
        name: 'ES6',
        grade: 4,
        sub: true
      }, {
        name: 'ES2016',
        grade: 4,
        sub: true
      }
    ]
  },
  {
    name: 'Frameworks',
    items: [
      {
        name: 'Svelte',
        grade: 3
      }, {
        name: 'Sapper',
        grade: 3
      }, {
        name: 'Vue.js',
        grade: 3
      }, {
        name: 'React',
        grade: 3
      }
    ]
  },
  {
    name: 'Tools',
    items: [
      {
        name: 'NodeJS',
        grade: 4
      }, {
        name: 'Webpack',
        grade: 2
      }, {
        name: 'Gulp',
        grade: 4
      }, {
        name: 'Babel',
        grade: 4
      }, {
        name: 'Parcel',
        grade: 3
      }, {
        name: 'CSS Modules',
        grade: 4
      }
    ]
  },
  {
    name: 'Utils',
    items: [
      {
        name: 'Bamboo',
        grade: 4
      }, {
        name: 'JIRA',
        grade: 4
      }, {
        name: 'GIT',
        grade: 4
      }, {
        name: 'BEM',
        grade: 5
      }, {
        name: 'Atomic CSS',
        grade: 5
      }, {
        name: 'Atomic Design',
        grade: 5
      }
    ]
  }
]