<template>
<div class="webdev_col">
    <p class="skill_title">{{skills.name}}</p>
    <skill v-for="skill in skills.items" :key="skill.id" :skill="skill" :sub="skill.sub"></skill>
</div>
</template>

<script>
import Skill from './Skill.vue'

export default {
    props: ['skills'],
    components: {
        Skill
    }
}
</script>
