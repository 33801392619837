<template>
<div v-bind:class="section.class" :id="section.anchor">
    <div class="art-gallery">
        <p class="art-gallery_rotate-title">using</p>
        <h1 class="art-gallery_title" v-bind:class="whiteSection">{{section.title}}</h1>
        <div class="art-gallery_desc" v-bind:class="whiteSection">
            <p v-for="desc in section.descriptions" :key="desc.id" class="art-gallery_text" v-html="desc">{{desc}}</p>
        </div>
        <img :src="section.background" class="art-gallery_img" alt="">
        <div class="art-gallery_asset" v-for="{title, thumbnails, images, hasUnity, unityLink} in section.galleries" :key="title">
            <h1 class="art-gallery_title art-gallery_title-sub">{{title}}</h1>
            <a v-if="unityLink" :href="unityLink" class="art-gallery_link" target="_blank">Asset Store</a>
            <gallery :thumbnails="thumbnails" :images="images" :anchor="section.anchor"></gallery>
        </div>
    </div>
</div>
</template>

<script>
import Gallery from '../components/gallery/Gallery'
import { assets } from '../js/artistGallery'

export default {
    data() {
        return {
            section: assets
        }
    },
    components: {
        Gallery
    }
}
</script>
